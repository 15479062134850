import PropTypes from 'prop-types';
import React from 'react';
import { assign } from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { querySelectors, mutateAsync } from 'redux-query';
import { Field, reduxForm, getFormValues } from 'redux-form';
import { message } from 'antd';
import { withRouter } from 'react-router-dom';
import { deactivateUser, activateUser, editUserDetail } from 'state/requests';
import { buttonStyle } from 'components/style/utils';
import FilterHeading from 'components/settings/users/FilterHeading';
import LoadingSpinner from 'components/style/LoadingSpinner';
import {
    FormInput,
    RadioGroup,
} from 'components/settings/users/FormComponents';
import Modal from 'components/style/Modal';
import { roles, STATUS_ACTIVE } from 'config/constants';
import { Select } from 'antd';
import { getPermissionGroups } from 'waypoint-requests';

class EditUserModal extends React.Component {
    static propTypes = {
        clickHandler: PropTypes.func,
        closeEditUserModal: PropTypes.func,
        user: PropTypes.object,
        isPending: PropTypes.bool,
        dispatch: PropTypes.func,
        formValues: PropTypes.object,
        deactivatePending: PropTypes.bool,
    };

    static contextTypes = {
        router: PropTypes.object,
    };

    state = {
        showDeactivateModal: false,
        isPending: false,
        selectedPermissionGroupId: null,
        permissionGroups: [],
    };

    componentDidMount() {
        this.fetchPermissionsGroups();
    }

    fetchPermissionsGroups = async () => {
        const { client_id } = this.props;
        const permissionGroups = await getPermissionGroups(client_id);
        this.setState({
            permissionGroups,
            selectedPermissionGroupId:
                permissionGroups?.find(
                    (pg) => pg.name === this.props.user?.permissionGroups[0],
                )?.id ?? null,
        });
    };

    toggleDeactivateModal = () => {
        this.setState({ showDeactivateModal: !this.state.showDeactivateModal });
    };

    deactivate = ({ firstname, lastname, id }) => {
        const { closeEditUserModal, dispatch } = this.props;
        dispatch(mutateAsync(deactivateUser(id))).then(({ status }) => {
            if (status !== 200) {
                message.error(
                    ` ${
                        firstname + ' ' + lastname
                    } was not deactivated successfully`,
                );
            } else {
                message.success(
                    ` ${
                        firstname + ' ' + lastname
                    } was deactivated successfully`,
                );
                closeEditUserModal();
            }
            return null;
        });
    };

    activate = ({ firstname, lastname, id }) => {
        const { closeEditUserModal, dispatch } = this.props;
        dispatch(mutateAsync(activateUser(id))).then(({ status }) => {
            if (status !== 200) {
                message.error(
                    ` ${
                        firstname + ' ' + lastname
                    } was not activated successfully`,
                );
            } else {
                message.success(
                    ` ${firstname + ' ' + lastname} was activated successfully`,
                );
                closeEditUserModal();
            }
            return null;
        });
    };

    isUserActive = () => {
        const { user } = this.props;
        return user.activeStatus === STATUS_ACTIVE;
    };

    buildPayload = () => {
        const { formValues, user } = this.props;
        const { firstname, lastname, roleHighest } = user;
        const { firstName, lastName, role } = formValues;
        const shouldEditFirstName = firstname !== firstName;
        const shouldEditLastName = lastname !== lastName;
        const didChooseAdmin =
            roleHighest === roles.CLIENT_USER && role === 'Admin';
        const didChooseUser =
            roleHighest === roles.CLIENT_ADMIN && role === 'User';

        const permissionGroupId = this.state.selectedPermissionGroupId
            ? [this.state.selectedPermissionGroupId]
            : [];

        const body = {};

        if (this.state.selectedPermissionGroupId !== null) {
            body.permission_group_ids = permissionGroupId;
        }

        if (didChooseAdmin || role === 'Admin') {
            body.permission_group_ids = null;
            body.role = roles.CLIENT_ADMIN;
        }

        if (shouldEditFirstName) {
            body.firstname = firstName;
        }

        if (shouldEditLastName) {
            body.lastname = lastName;
        }

        if (didChooseUser) {
            body.role = roles.CLIENT_USER;
        }

        return {
            body,
        };
    };

    editUser = () => {
        const { closeEditUserModal, dispatch, user } = this.props;
        const { firstname, lastname, id } = user;
        const toastrNames = {
            firstname,
            lastname,
        };
        const { body } = this.buildPayload();
        if (body.firstname) {
            toastrNames.firstname = body.firstname;
        }
        if (body.lastname) {
            toastrNames.lastname = body.lastname;
        }
        this.setState({ isPending: true });
        dispatch(mutateAsync(editUserDetail(body, id))).then(({ status }) => {
            if (status === 200) {
                message.success(
                    `${toastrNames.firstname} ${toastrNames.lastname} was edited successfully`,
                );
                closeEditUserModal();
            } else {
                message.error(
                    `${toastrNames.firstname} ${toastrNames.lastname} was edited not successfully`,
                );
            }
        });
    };

    render() {
        const { showDeactivateModal, isPending } = this.state;

        const { closeEditUserModal, user, deactivatePending } = this.props;

        const { firstname, lastname, email } = user;

        const modalSection = {
            height: '60px',
            width: '100%',
            marginBottom: '15px',
        };

        const buttonSection = {
            height: '50px',
            width: '100%',
            display: 'flex',
            marginBottom: '3%',
            justifyContent: 'space-between',
        };

        const inputStyle = {
            float: 'left',
            boxSizing: 'border-box',
            height: '33px',
            width: '229px',
            maxWidth: '100%',
            border: '1px solid rgba(155,155,155,0.4)',
        };

        const confirmTitle = this.isUserActive()
            ? 'Are you sure you want to deactivate?'
            : 'Are you sure you want to activate?';

        const activeText = this.isUserActive() ? 'Deactivate' : 'Activate';

        const activeOrDeactivateColorStyle = this.isUserActive()
            ? '#D0021B'
            : '#00ADE2';

        const roleSelectedIsAdmin = this.props.formValues?.role === 'Admin';

        return showDeactivateModal ? (
            <Modal title={confirmTitle} clickHandler={closeEditUserModal}>
                {deactivatePending ? (
                    <div
                        style={{
                            height: '137px',
                            paddingTop: '29px',
                        }}
                    >
                        <LoadingSpinner style={{ textAlign: 'center' }} />
                    </div>
                ) : (
                    <div>
                        <div
                            style={assign({}, modalSection, {
                                height: 'auto',
                                paddingTop: '5px',
                                fontSize: '18px',
                            })}
                        >
                            {firstname + ' ' + lastname}
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div
                                style={assign({}, buttonSection, {
                                    order: 1,
                                    justifyContent: 'flex-end',
                                    marginBottom: '1%',
                                })}
                            >
                                <button
                                    style={assign(
                                        {},
                                        buttonStyle(
                                            100,
                                            32,
                                            activeOrDeactivateColorStyle,
                                        ),
                                        {
                                            color: '#F0F3F6',
                                            backgroundColor:
                                                activeOrDeactivateColorStyle,
                                            width: '96px',
                                        },
                                    )}
                                    onClick={() => {
                                        if (this.isUserActive()) {
                                            this.deactivate(user);
                                        } else {
                                            this.activate(user);
                                        }
                                    }}
                                >
                                    {activeText}
                                </button>
                                <button
                                    style={assign(
                                        {},
                                        buttonStyle(100, 32, '#00ADE2'),
                                        { marginLeft: '12px' },
                                    )}
                                    onClick={this.toggleDeactivateModal}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        ) : (
            <Modal title="Edit User" clickHandler={closeEditUserModal}>
                {isPending || !user ? (
                    <div
                        style={{
                            height: 'auto',
                            paddingTop: '29px',
                        }}
                    >
                        <LoadingSpinner style={{ textAlign: 'center' }} />
                    </div>
                ) : (
                    <div style={{ order: 2 }}>
                        <div
                            style={assign({}, modalSection, { height: '35px' })}
                        >
                            <FilterHeading isModal={true} heading="EMAIL" />
                            {email}
                        </div>
                        <div style={modalSection}>
                            <Field
                                name="firstName"
                                title="FIRST NAME"
                                style={inputStyle}
                                component={FormInput}
                            />
                        </div>
                        <div style={modalSection}>
                            <Field
                                name="lastName"
                                title="LAST NAME"
                                style={inputStyle}
                                component={FormInput}
                            />
                        </div>
                        <div
                            style={assign({}, modalSection, {
                                height: '70px',
                            })}
                        >
                            <Field
                                name="role"
                                title="USER ROLE"
                                style={{ fontSize: '14px' }}
                                component={RadioGroup}
                            />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <p style={{ textTransform: 'uppercase' }}>
                                Permission Group
                            </p>
                            <Select
                                style={{ width: 200 }}
                                defaultValue={
                                    this.state.selectedPermissionGroupId
                                }
                                value={
                                    roleSelectedIsAdmin
                                        ? null
                                        : this.state.selectedPermissionGroupId
                                }
                                disabled={roleSelectedIsAdmin}
                                allowClear
                                onClear={() => {
                                    this.setState({
                                        selectedPermissionGroupId: null,
                                    });
                                }}
                                onChange={(value) => {
                                    this.setState({
                                        selectedPermissionGroupId: value,
                                    });
                                }}
                                options={[
                                    { value: null, label: 'All Access' },
                                    ...this.state.permissionGroups.map(
                                        (group) => {
                                            return {
                                                value: group.id,
                                                label: group.name,
                                            };
                                        },
                                    ),
                                ]}
                            />
                        </div>
                        <div style={buttonSection}>
                            <button
                                style={assign(
                                    {},
                                    buttonStyle(
                                        100,
                                        32,
                                        activeOrDeactivateColorStyle,
                                    ),
                                    {
                                        order: 1,
                                        float: 'left',
                                    },
                                )}
                                onClick={this.toggleDeactivateModal}
                            >
                                {activeText}
                            </button>
                            <div
                                style={{
                                    order: 2,
                                    float: 'right',
                                    alignSelf: 'flex-end',
                                }}
                            >
                                <button
                                    style={buttonStyle(100, 32, '#00ADE2')}
                                    onClick={closeEditUserModal}
                                >
                                    Cancel
                                </button>
                                <button
                                    style={assign(
                                        {},
                                        buttonStyle(100, 32, '#00ADE2'),
                                        {
                                            color: '#F0F3F6',
                                            backgroundColor: '#00ADE2',
                                            width: '96px',
                                            order: 3,
                                            float: 'right',
                                            marginLeft: '12px',
                                        },
                                    )}
                                    onClick={this.editUser}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        );
    }
}

const mapState = (state, props) => {
    const { userId } = props;
    const { client_id } = state.user;
    const user = state.users[userId];
    const { firstname, lastname, roleHighest } = user;
    const initialValues = {
        firstName: firstname,
        lastName: lastname,
        role: roleHighest === roles.CLIENT_ADMIN ? 'Admin' : 'User',
    };
    const formValues = getFormValues('editUserForm')(state);
    const deactivatePending = querySelectors.isPending(state.queries, {
        url: `/api/admin/users/${userId}`,
    });
    return {
        userId,
        client_id,
        user,
        initialValues,
        deactivatePending,
        formValues,
    };
};

const mapDispatch = (dispatch) => {
    return {
        dispatch,
    };
};

export default withRouter(
    compose(
        connect(mapState, mapDispatch),
        reduxForm({ form: 'editUserForm' }),
    )(EditUserModal),
);
