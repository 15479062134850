export enum SummaryTypes {
    average = 'avg',
    count = 'count',
    custom = 'custom',
    max = 'max',
    min = 'min',
    sum = 'sum',
}

export const summaryTypeLabels: { [key in SummaryTypes]: string } = {
    [SummaryTypes.average]: 'Average',
    [SummaryTypes.count]: 'Count',
    [SummaryTypes.custom]: 'Custom',
    [SummaryTypes.max]: 'Max',
    [SummaryTypes.min]: 'Min',
    [SummaryTypes.sum]: 'Sum',
};

export const getSummaryLabel = (summaryType: SummaryTypes | null) => {
    return `${summaryType && summaryTypeLabels[summaryType] ? `${summaryTypeLabels[summaryType]}: ` : ''}{0}`;
};

export const safeDivision = (
    dividend: number,
    divisor: number,
    decimalPlace = 4,
) => {
    const result = dividend / divisor;

    if (isFinite(result)) {
        return parseFloat(result.toFixed(decimalPlace));
    }

    return 0;
};

export const formatCurrency = (value: number | null, decimals = 2): string => {
    return value !== null && !isNaN(value)
        ? value.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
          })
        : '--';
};

export const formatPercentages = (value: number | null): string => {
    return value
        ? Number(value).toLocaleString(undefined, {
              style: 'percent',
              minimumFractionDigits: 2,
          })
        : '--';
};

export const formatNumber = (value: number | null, decimals = 2): string => {
    return value
        ? Number(value).toLocaleString(undefined, {
              minimumFractionDigits: decimals,
              maximumFractionDigits: decimals,
          })
        : '--';
};
