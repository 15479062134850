import React, {
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useGetKPIs } from 'waypoint-hooks/data-access/useGetKPIs';
import {
    useGetAttributes,
    useGetDataAvailabilityDate,
    useGetGroupableAttributes,
    useGetSavedConfigurationById,
    useGetSavedConfigurations,
    useGetSelectedFilteredEntityCodes,
    useGetUserData,
    useSelectedDataLevel,
    useSortByGroupSummaryInfo,
} from 'waypoint-hooks';
import {
    AppFeaturePermissions,
    AttributeDefinition,
    getAttributeDefinitionFromKPIRequest,
    getKPIRequestKey,
    KPIColumnMappings,
    KPIGridConfiguration,
    KPIMetric,
    KPISummaryOptions,
    StandardKPI,
    summaryTypeLabels,
    SummaryTypes,
} from 'shared-types';
import { DataGrid } from 'devextreme-react';

import {
    Column,
    Export,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    HeaderFilter,
    Item,
    Pager,
    Paging,
    Scrolling,
    Summary,
    TotalItem,
    Toolbar,
    SortByGroupSummaryInfo,
} from 'devextreme-react/data-grid';
import { Button, Divider, Empty, Select, Skeleton, Space, Tooltip } from 'antd';
import {
    dataGridStyles,
    mapAttributeFormat,
    mapAttributeType,
} from 'components/attributes/card/utils';
import moment from 'moment/moment';
import {
    DatePicker,
    ExpandAndCollapseButton,
    SortByGroupSummarySortSelect,
} from 'waypoint-react';
import { CellInfoType, Entity, SelectedDataLevel } from 'waypoint-types';
import { keyBy, uniqBy } from 'lodash';
import { Link, useHistory, useParams } from 'react-router-dom';
import { EditOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { RouteUrls } from 'routes/RouteConstants';
import { CustomSummaryInfo, ExportingEvent } from 'devextreme/ui/data_grid';
import {
    capitalizeFirstLetter,
    exportExcelFromDevExtremeDataGrid,
    onContentReady,
} from 'waypoint-utils';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import { applyGroupAndHeaderStylingOnRowPrepared } from 'waypoint-utils/dev-extreme/groupingUtils';
import { css } from 'emotion';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { AttributesGroupBySelect } from 'components/attributesGroupBySelect/AttributesGroupBySelect';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';

export function KPIGridPage(): ReactElement {
    const history = useHistory();

    const entityCodes = useGetSelectedFilteredEntityCodes();
    const selectedDataLevel = useSelectedDataLevel();
    const groupableAttributes = useGetGroupableAttributes();

    const { userId, clientId } = useGetUserData();
    const { data: savedGrids } = useGetSavedConfigurations(
        userId,
        clientId,
        'kpis',
    );

    const { data: attributes } = useGetAttributes(entityCodes);
    const { gridId: gridIdFromURL } = useParams() ?? {};

    const [selectedGridId, setSelectedGridId] = useState<string | undefined>(
        gridIdFromURL,
    );
    const gridContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!selectedGridId) {
            return;
        }

        history.push(RouteUrls.KPI_GRID.replace(':gridId', selectedGridId));
    }, [selectedGridId]);

    useEffect(() => {
        if (!gridIdFromURL) {
            return;
        }

        setSelectedGridId(gridIdFromURL);
    }, [gridIdFromURL]);

    const { data: gridSettings } =
        useGetSavedConfigurationById<KPIGridConfiguration>(
            selectedGridId ?? null,
        );

    const { data: asOfDate, isLoading: isLoadingAsOfDate } =
        useGetDataAvailabilityDate(entityCodes);
    const [date, setDate] = useState<Date>();

    useEffect(() => {
        setDate(asOfDate);
    }, [asOfDate]);

    const [groupingType, setGroupingType] =
        useState<EntityDataGroupingKeys | null>(null);
    const [groupByAttributeCode, setGroupByAttributeCode] = useState<
        string | null
    >(null);

    useEffect(() => {
        setGroupingType(
            gridSettings?.filters_json.groupBy.attributeCode
                ? EntityDataGroupingKeys.Attributes
                : null,
        );
        setGroupByAttributeCode(
            gridSettings?.filters_json.groupBy?.attributeCode ?? null,
        );
    }, [gridSettings?.filters_json?.groupBy?.attributeCode, selectedGridId]);

    const gridOptions = savedGrids?.map((grid) => {
        return {
            label: grid.name,
            value: grid.id,
        };
    });

    const gridSelect = (
        <Tooltip title="Select a saved view">
            <Select
                variant="filled"
                value={selectedGridId}
                options={gridOptions}
                placeholder="Select a view"
                style={{
                    minWidth: '200px',
                    marginBottom: '10px',
                    marginTop: '10px',
                }}
                onChange={(newGridId: string) => setSelectedGridId(newGridId)}
                labelRender={({ value, label }) => {
                    if (value === selectedGridId) {
                        return 'Jump to View...';
                    }

                    return label;
                }}
            />
        </Tooltip>
    );

    const editViewButton = (
        <Tooltip placement={'topLeft'} title="Edit View">
            <Button onClick={() => navigateToEditor(selectedGridId)}>
                <EditOutlined />
            </Button>
        </Tooltip>
    );

    const attributeGroupBySelect = (
        <AttributesGroupBySelect
            groupingSelection={groupingType}
            setGroupingSelection={setGroupingType}
            attributeSelection={
                groupableAttributes.find(
                    (attr) => attr.key === groupByAttributeCode,
                ) ??
                groupableAttributes[0] ??
                null
            }
            attributes={groupableAttributes}
            setAttributeSelection={({ key }) => setGroupByAttributeCode(key)}
        />
    );

    function navigateToEditor(gridId?: string) {
        history.push(
            RouteUrls.KPI_GRID_EDITOR.replace(':gridId', gridId ?? 'new'),
        );
    }

    const noViewSelected = (
        <Empty description="No view selected. Please choose or add one from the menu in the top-right." />
    );

    const datePicker = (
        <DatePicker
            picker="month"
            style={{ width: '150px', fontWeight: 400 }}
            format={'MMMM YYYY'}
            value={moment(date)}
            allowClear={false}
            disabledDate={(momentDate) => momentDate.isAfter(asOfDate)}
            onChange={(value: moment.Moment) => {
                if (value) {
                    setDate(value.toDate());
                }
            }}
            placeholder="Select month"
        />
    );

    const extra = (
        <Space direction="horizontal">
            {selectedGridId ? editViewButton : null}
        </Space>
    );

    function useAvailableHeight(containerRef: React.RefObject<HTMLDivElement>) {
        const [availableHeight, setAvailableHeight] = useState<number>(0);

        useEffect(() => {
            const calculateHeight = () => {
                if (!containerRef.current) return;

                const viewportHeight = window.innerHeight;
                const containerRect =
                    containerRef.current.getBoundingClientRect();
                const containerTop = containerRect.top;

                const padding = 16;
                const calculatedHeight =
                    viewportHeight - containerTop - padding;

                setAvailableHeight(Math.max(calculatedHeight, 300));
            };

            calculateHeight();
            window.addEventListener('resize', calculateHeight);

            return () => {
                window.removeEventListener('resize', calculateHeight);
            };
        }, [containerRef]);

        return availableHeight;
    }

    const availableHeight = useAvailableHeight(gridContainerRef);

    return (
        <div style={{ width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingLeft: 12,
                    paddingRight: 12,
                }}
            >
                <Space
                    style={{ backgroundColor: 'white', paddingLeft: '12px' }}
                >
                    <Link to={RouteUrls.KPI_GRID_INDEX}>
                        <LeftCircleOutlined style={{ marginRight: 5 }} />
                        Back to KPI Views
                    </Link>
                    <Divider type="vertical" />

                    {gridSelect}
                </Space>
            </div>

            <div className="header">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderBottom: '1px solid lightgrey',
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 10,
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Space size={'large'}>
                            <h1
                                style={{
                                    marginTop: '10px',
                                }}
                            >
                                {gridSettings?.name ?? ''}
                            </h1>
                            {attributeGroupBySelect}
                            <Space size="small">
                                <span style={{ fontSize: '14px' }}>
                                    Financials Thru:
                                </span>
                                {datePicker}
                            </Space>
                        </Space>
                        <div>{editViewButton}</div>
                    </div>
                </div>
            </div>
            <div
                ref={gridContainerRef}
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    backgroundColor: '#fff',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PermissionedWrapper
                    featureKey={AppFeaturePermissions.KPIs}
                    showDisabledView={true}
                >
                    {isLoadingAsOfDate ||
                    !date ||
                    !attributes ||
                    (selectedGridId && !gridSettings) ||
                    !savedGrids ? (
                        <Skeleton />
                    ) : !selectedGridId ? (
                        noViewSelected
                    ) : (
                        <KPIGrid
                            date={date}
                            selectedDataLevel={selectedDataLevel}
                            entityCodes={entityCodes}
                            metrics={gridSettings?.filters_json.metrics ?? []}
                            groupByAttributeCode={
                                groupingType ? groupByAttributeCode : null
                            }
                            attributeDefinitions={
                                attributes.attributeDefinitions
                            }
                            entities={attributes.entities}
                            title={gridSettings?.name ?? ''}
                            availableHeight={availableHeight}
                        />
                    )}
                </PermissionedWrapper>
            </div>
        </div>
    );
}

interface KPIGridProps {
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    date: Date;
    metrics: KPIMetric[];
    groupByAttributeCode: string | null;
    attributeDefinitions: AttributeDefinition[];
    entities: Entity[];
    title: string;
    availableHeight: number;
}

function getColumnMappingAndAttributeDefinition(
    metric: KPIMetric,
    attributeDefinitions: AttributeDefinition[],
) {
    const columnMappingGenerator = KPIColumnMappings[metric.kpi];

    if (!columnMappingGenerator) {
        return null;
    }

    const attributeDefinition = metric.attributeCode
        ? attributeDefinitions.find(
              (attr) => attr.attribute_code === metric.attributeCode,
          )
        : undefined;

    const columnMappings = columnMappingGenerator(metric, attributeDefinition);

    const columnMapping = columnMappings.find(
        (mapping) => mapping.key === getKPIRequestKey(metric, metric.metric),
    );

    if (!columnMapping) {
        return null;
    }

    return {
        columnMapping,
        attributeDefinition,
    };
}

const capitalizedCellValue = css`
    text-transform: capitalize;
`;

const groupCell = (options: CellInfoType) => {
    return <div className={capitalizedCellValue}>{options.value}</div>;
};

export function KPIGrid({
    entityCodes,
    selectedDataLevel,
    date,
    metrics,
    groupByAttributeCode,
    attributeDefinitions,
    entities,
    title,
    availableHeight,
}: KPIGridProps): ReactElement {
    const [expandButtonEnable, setExpandButtonEnable] = useState<boolean>(true);
    const [allExpanded, setAllExpanded] = useState<boolean>(false);

    const [requests, setRequests] = useState<KPIMetric[]>([]);

    const {
        sortSelection,
        setSortSelection,
        sortOrderAscending,
        sortVisible,
        sortExcludedColumns,
        toggleSortOrder,
        toggleSortSettings,
    } = useSortByGroupSummaryInfo();

    const ref = useRef<DataGridRef>(null);

    const toggleAllExpanded = () => {
        setAllExpanded(!allExpanded);
    };

    const groupableAttributesData = useGetGroupableAttributes();

    useEffect(() => {
        ref.current?.instance().clearFilter();

        const potentialGroupAttributeCodes = groupableAttributesData.map(
            (attr: { key: string }) => attr.key,
        );

        const baseMetrics = uniqBy(metrics, (metric) =>
            getKPIRequestKey(metric, metric.metric),
        );

        const additionalGroupingAttributes = potentialGroupAttributeCodes
            .filter(
                (attrCode: string) =>
                    !baseMetrics.find(
                        (metric) => metric.attributeCode === attrCode,
                    ),
            )
            .map((attrCode: string) => ({
                kpi: StandardKPI.attributeValues,
                attributeCode: attrCode,
            }));

        setRequests([...baseMetrics, ...additionalGroupingAttributes]);
    }, [metrics, groupableAttributesData]);

    const { data: getKPIsResponse, isLoading } = useGetKPIs({
        entityCodes,
        selected_data_level: selectedDataLevel,
        date: moment(date).format('YYYY-MM-DD'),
        kpi_requests: requests,
    });

    const { kpiData: kpis, occupancyByEntityCode } = getKPIsResponse ?? {};

    const groupItems = requests.reduce<{ label: string; value: string }[]>(
        (result, metric) => {
            const { columnMapping } =
                getColumnMappingAndAttributeDefinition(
                    metric,
                    attributeDefinitions,
                ) ?? {};

            if (!columnMapping?.getSummaryMapping) {
                return result;
            }

            return [
                ...result,
                {
                    label: columnMapping.title,
                    value: columnMapping.key,
                },
            ];
        },
        [],
    );

    const shouldDisplayMetric = (metric: KPIMetric): boolean => {
        const isExplicitlySelected = metrics.some(
            (selectedMetric) =>
                getKPIRequestKey(selectedMetric, selectedMetric.metric) ===
                getKPIRequestKey(metric, metric.metric),
        );
        const isGroupingAttribute =
            !!groupByAttributeCode &&
            metric.attributeCode === groupByAttributeCode;

        return isExplicitlySelected || isGroupingAttribute;
    };

    const columns = requests.reduce<JSX.Element[]>((result, metric) => {
        if (!shouldDisplayMetric(metric)) {
            return result;
        }

        const { columnMapping, attributeDefinition } =
            getColumnMappingAndAttributeDefinition(
                metric,
                attributeDefinitions,
            ) ?? {};

        if (!columnMapping) {
            return result;
        }

        const dataType =
            mapAttributeType(attributeDefinition?.type, false) ?? 'number';
        const format =
            columnMapping.format ??
            mapAttributeFormat(
                attributeDefinition?.type,
                attributeDefinition?.format,
                attributeDefinition?.precision,
            );

        const isGroupByAttributeColumn =
            !!groupByAttributeCode &&
            metric.attributeCode === groupByAttributeCode;

        const calculateCellAndGroupValue = (
            rowData: Record<string, object[]>,
        ) => {
            if (rowData[columnMapping.key]) {
                if (Array.isArray(rowData[columnMapping.key])) {
                    return (
                        rowData[columnMapping.key].sort().join(', ').trim() ||
                        (isGroupByAttributeColumn
                            ? columnMapping.defaultGroupedLabel ?? ''
                            : '')
                    );
                }

                return rowData[columnMapping.key];
            }

            return isGroupByAttributeColumn
                ? columnMapping.defaultGroupedLabel ?? ''
                : rowData[columnMapping.key];
        };

        const column = (
            <Column
                format={format}
                caption={columnMapping.title}
                dataField={columnMapping.key}
                dataType={dataType}
                key={columnMapping.key}
                showWhenGrouped
                groupCellRender={groupCell}
                fixed={isGroupByAttributeColumn}
                fixedPosition="left"
                visibleIndex={isGroupByAttributeColumn ? 0 : undefined}
                groupIndex={isGroupByAttributeColumn ? 0 : undefined}
                calculateGroupValue={calculateCellAndGroupValue}
                customizeText={
                    attributeDefinition?.is_multiselect
                        ? ({ value, valueText }) => {
                              if (Array.isArray(value)) {
                                  return value.join(', ');
                              }

                              return valueText;
                          }
                        : undefined
                }
                calculateCellValue={
                    attributeDefinition?.is_multiselect
                        ? calculateCellAndGroupValue
                        : undefined
                }
                minWidth={150}
            />
        );

        return [...result, column];
    }, []);

    const propertyTotalItem = (
        <TotalItem
            column="name"
            summaryType="count"
            displayFormat="{0}"
            showInColumn="name"
        />
    );

    const propertyGroupItem = (
        <GroupItem
            column="name"
            summaryType="count"
            displayFormat="{0}"
            showInColumn="name"
            alignByColumn={true}
        />
    );

    const summaryItems = requests.reduce<JSX.Element[]>(
        (result, metric) => {
            if (!shouldDisplayMetric(metric)) {
                return result;
            }

            const { columnMapping, attributeDefinition } =
                getColumnMappingAndAttributeDefinition(
                    metric,
                    attributeDefinitions,
                ) ?? {};

            if (!columnMapping?.getSummaryMapping) {
                return result;
            }

            const { summaryType, valueFormat } =
                columnMapping.getSummaryMapping();

            const format =
                valueFormat ??
                mapAttributeFormat(
                    attributeDefinition?.type,
                    attributeDefinition?.format,
                    attributeDefinition?.summary_type === SummaryTypes.average
                        ? Math.max(2, attributeDefinition?.precision ?? 0)
                        : attributeDefinition?.precision ?? 0,
                );

            const commonSummaryAttributes = {
                column:
                    summaryType !== 'custom' ? columnMapping.key : undefined,
                showInColumn:
                    summaryType === 'custom' ? columnMapping.key : undefined,
                summaryType,
                valueFormat: format,
                displayFormat: `${attributeDefinition?.summary_type && summaryTypeLabels[attributeDefinition.summary_type] ? `${summaryTypeLabels[attributeDefinition.summary_type]}: ` : ''}{0}`,
                name: columnMapping.key,
            };

            const summaryItem = <TotalItem {...commonSummaryAttributes} />;

            const groupItem = (
                <GroupItem {...commonSummaryAttributes} alignByColumn={true} />
            );

            return [...result, summaryItem, groupItem];
        },
        [propertyTotalItem, propertyGroupItem],
    );

    const kpisByEntityCode = entityCodes.reduce<{
        [key: string]: { [key: string]: number | string | string[] };
    }>((result, entityCode) => {
        const resultsByColumn = kpis?.reduce<{
            [key: string]: number | string | string[];
        }>((results, kpiResponse) => {
            const columnMappingGenerator = KPIColumnMappings[kpiResponse.kpi];

            if (!columnMappingGenerator) {
                return results;
            }

            const attributeDefinition = getAttributeDefinitionFromKPIRequest(
                kpiResponse,
                attributeDefinitions,
            );

            const columnMappings = columnMappingGenerator(
                kpiResponse,
                attributeDefinition,
            );

            const dataForEntityCode = kpiResponse.results.find(
                ({ entityCode: ec }) => ec === entityCode,
            );

            if (!dataForEntityCode) {
                columnMappings.forEach(
                    ({ key, defaultValue }) =>
                        (results[key] = defaultValue ?? 0),
                );

                return results;
            }

            columnMappings.forEach(
                ({ getValue, key, defaultValue }) =>
                    (results[key] = dataForEntityCode.data.length
                        ? getValue(dataForEntityCode.data)
                        : defaultValue ?? 0),
            );

            return results;
        }, {});

        const entity = entities.find(
            (entity) => entity.entityCode === entityCode,
        );

        result[entityCode] = {
            ...(result[entityCode] ?? {
                entityCode,
                name: entity?.displayName ?? '--',
            }),
            ...resultsByColumn,
        };

        return result;
    }, {});

    const metricsByKey = keyBy(requests, (metric) =>
        getKPIRequestKey(metric, metric.metric),
    );

    const onExporting = useCallback(
        async (e: ExportingEvent) => {
            await exportExcelFromDevExtremeDataGrid(e, {
                worksheetName: `KPIs - ${title}`,
                filename: `KPIs - ${title}.xlsx`,
            });
        },
        [title],
    );

    const calculateCustomSummary = (options: CustomSummaryInfo) => {
        if (!kpis) {
            return;
        }

        if (!options.name) {
            return;
        }

        const metricForSummary = metricsByKey[options.name];

        if (!metricForSummary) {
            return;
        }

        const { columnMapping } =
            getColumnMappingAndAttributeDefinition(
                metricForSummary,
                attributeDefinitions,
            ) ?? {};

        if (!columnMapping?.calculateCustomSummary) {
            return;
        }

        const key = getKPIRequestKey(metricForSummary);

        const data = kpis
            ?.find((kpi) => getKPIRequestKey(kpi) === key)
            ?.results.find(
                ({ entityCode }) => entityCode === options.value?.entityCode,
            )?.data;

        const summaryOptions = options as unknown as KPISummaryOptions;
        summaryOptions.occupancy = (occupancyByEntityCode
            ? occupancyByEntityCode[options.value?.entityCode]
            : {
                  totalArea: 0,
                  totalUnits: 0,
                  occupiedUnits: 0,
                  occupiedArea: 0,
                  waleMonths: 0,
              }) ?? {
            totalArea: 0,
            totalUnits: 0,
            occupiedUnits: 0,
            occupiedArea: 0,
            waleMonths: 0,
        };

        summaryOptions.value = data;
        summaryOptions.metric = metricForSummary;

        return columnMapping.calculateCustomSummary(summaryOptions);
    };

    if (!getKPIsResponse || isLoading) {
        return <Skeleton />;
    }

    return (
        <DataGrid
            ref={ref}
            className={dataGridStyles}
            style={{
                backgroundColor: '#fff',
                height: '100%',
                padding: 15,
            }}
            height={availableHeight > 0 ? `${availableHeight}px` : '85vh'}
            dataSource={Object.values(kpisByEntityCode)}
            allowColumnResizing={true}
            columnResizingMode="widget"
            allowColumnReordering={false}
            hoverStateEnabled={true}
            showRowLines={true}
            showBorders={true}
            wordWrapEnabled={true}
            showColumnLines={true}
            onExporting={onExporting}
            onRowPrepared={applyGroupAndHeaderStylingOnRowPrepared}
            onContentReady={(e) => {
                onContentReady({
                    e,
                    toggleFunc: setExpandButtonEnable,
                });
            }}
            onOptionChanged={toggleSortSettings}
        >
            <Toolbar>
                <Item name="groupPanel" />
                <Item name="exportButton" />
                <Item location="before">
                    <ExpandAndCollapseButton
                        expanded={allExpanded}
                        toggleExpanded={toggleAllExpanded}
                        expandButtonEnable={expandButtonEnable}
                    />
                </Item>

                <Item location="before" visible={sortVisible}>
                    <SortByGroupSummarySortSelect
                        groupingOptions={groupItems}
                        sortExcludedColumns={sortExcludedColumns}
                        sortSelection={sortSelection}
                        setSortSelection={setSortSelection}
                        sortOrderAscending={sortOrderAscending}
                        toggleSortOrder={toggleSortOrder}
                    />
                </Item>
            </Toolbar>

            <Grouping autoExpandAll={allExpanded} expandMode="rowClick" />

            <Export enabled={true} allowExportSelectedData={false} />
            <Scrolling mode="standard" showScrollbar="onScroll" />

            <HeaderFilter allowSelectAll={true} allowSearch={true} />
            <FilterPanel visible={true} />
            <FilterRow visible={true} />

            <Paging enabled={true} defaultPageSize={60} />
            <Pager
                visible
                displayMode={'full'}
                showPageSizeSelector={true}
                allowedPageSizes={[20, 40, 60, 80, 100]}
                showNavigationButtons={true}
                showInfo={true}
                infoText="Page {0} of {1} ({2} items)"
            />

            <Column
                caption="Property"
                dataField="name"
                minWidth={200}
                sortOrder="asc"
                fixed={!groupByAttributeCode}
                fixedPosition="left"
                allowHeaderFiltering
                allowFiltering
            />

            {columns}

            <Summary calculateCustomSummary={calculateCustomSummary}>
                {summaryItems}
            </Summary>

            <SortByGroupSummaryInfo
                summaryItem={sortSelection}
                sortOrder={sortOrderAscending ? 'asc' : 'desc'}
            />
        </DataGrid>
    );
}
