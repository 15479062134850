import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import DataGrid, {
    GroupItem,
    SearchPanel,
    ColumnChooser,
    Export,
    Paging,
    FilterRow,
    HeaderFilter,
    Pager,
    StateStoring,
    Grouping,
    GroupPanel,
    FilterPanel,
    Summary,
    TotalItem,
    Toolbar,
    Item,
    Editing,
    Column,
    DataGridRef,
    ColumnChooserSelection,
    Position,
    Scrolling,
} from 'devextreme-react/data-grid';
import { ExportingEvent, InitializedEvent } from 'devextreme/ui/data_grid';
import {
    exportExcelFromDevExtremeDataGrid,
    onContentReady,
} from 'waypoint-utils';
import {
    applyStoredConfiguration,
    createSavedConfigurationPayload,
} from 'components/saved-configurations';
import { ExpandAndCollapseButton } from 'waypoint-react';
import {
    AttributeData,
    AttributeTypes,
    DATA_GRID_HEIGHT_OFFSET,
    dataGridStyles,
    AttributeGridRowValueTypes,
    reservedDataGridAttributesColumns,
    formatValue,
    prepareAttributeValuesPayload,
    getAttributesColumn,
    getAttributeFormatter,
    mapAttributeFormat,
} from './utils';
import {
    CustomizeCellInfoType,
    Entity,
    SavedConfiguration,
    SavedConfigurationState,
} from 'waypoint-types';
import {
    AttributeDefinition,
    AttributeHistoryData,
    AttributeValue,
    getSummaryLabel,
    summaryTypeLabels,
    SummaryTypes,
} from 'shared-types';
import createOrUpdateEntityAttributeValues, {
    UpdateEntityAttributeValuesParams,
} from 'waypoint-requests/attributes/createOrUpdateEntityAttributeValues';
import DataSource from 'devextreme/data/data_source';
import { message, Modal, Select } from 'antd';
import { cloneDeep } from 'lodash';
import { GetAttributesV2Response } from 'waypoint-requests/attributes/getAttributesV2';
import { KeyedMutator } from 'swr';
import { AttributeFromAPI } from 'contexts';
import { CustomizeCell } from 'waypoint-utils/dev-extreme/exportExcelFromDevExtremeDataGrid';
import { css } from 'emotion';
import { differenceInDays, format, parseISO } from 'date-fns';
import { DASH_DASH } from 'config/constants';
import { customizeBoolean } from 'waypoint-utils/dev-extreme/columnRenderer';

const { Option } = Select;

export const AttributeValueHistoryModal = ({
    isHistoryModalOpen,
    setIsHistoryModalOpen,
    historyData,
}: {
    isHistoryModalOpen: boolean;
    setIsHistoryModalOpen: (open: boolean) => void;
    historyData: AttributeHistoryData[];
}) => {
    enum FilterByOptions {
        Last7Days = '7',
        Last30Days = '30',
        All = 'all',
    }

    const modalStyle = css`
        width: 1200px !important;
        @media screen and (max-width: 850px) {
            width: 450px !important;
        }
        .ant-modal-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h2 {
                margin: 0 !important;
            }
        }
        .ant-modal-footer {
            margin-top: 24px !important;
            text-align: right;
        }
    `;

    const [selectedFilter, setSelectedFilter] = useState<string>(
        FilterByOptions.Last7Days,
    );

    const filteredData = useMemo(() => {
        if (selectedFilter === FilterByOptions.All) return historyData;

        const daysAgo = parseInt(selectedFilter);
        const now = new Date();

        return historyData.filter((item) => {
            if (!item.updatedAt) return false;
            const itemDate = parseISO(item.updatedAt);
            return differenceInDays(now, itemDate) <= daysAgo;
        });
    }, [selectedFilter, historyData]);

    const onClose = () => {
        setIsHistoryModalOpen(false);
    };

    const handleFilterChange = (value: string) => {
        setSelectedFilter(value);
    };

    const renderCell = (rowData: AttributeHistoryData, attribute: string) => {
        const attributeKey = attribute as keyof AttributeHistoryData;
        const attributeValue = rowData[attributeKey];

        if (!attributeValue) {
            return DASH_DASH;
        }

        if (rowData.type === AttributeTypes.boolean.value) {
            return customizeBoolean({
                value: attributeValue,
            } as CustomizeCellInfoType);
        }

        if (rowData.type === AttributeTypes.number.value) {
            return getAttributeFormatter(
                rowData.type,
                rowData.format ?? undefined,
                rowData.precision,
            )(attributeValue);
        }

        return formatValue(
            attributeValue,
            true,
            rowData.type,
            rowData.format,
            rowData.precision,
        );
    };

    return (
        <Modal
            className={modalStyle}
            title={
                <div>
                    <h2>Attribute Edit History</h2>
                    <p style={{ fontSize: '12px', color: '#666' }}>
                        (Only tracks the latest update per attribute)
                    </p>
                </div>
            }
            open={isHistoryModalOpen}
            onCancel={onClose}
            footer={null}
        >
            <Select
                style={{ width: '100%' }}
                value={selectedFilter}
                onChange={handleFilterChange}
                placeholder="Select a filter"
            >
                <Option value={FilterByOptions.Last7Days}>Last 7 Days</Option>
                <Option value={FilterByOptions.Last30Days}>Last 30 Days</Option>
                <Option value={FilterByOptions.All}>All</Option>
            </Select>
            <DataGrid
                dataSource={filteredData}
                showBorders={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                height="calc(100vh - 250px)"
                wordWrapEnabled={true}
            >
                <Column
                    dataField="updatedAt"
                    caption="Updated Date"
                    allowGrouping
                    format="shortDate"
                />
                <Column
                    dataField="entity"
                    caption="Entity"
                    allowGrouping
                    width={200}
                />
                <Column
                    dataField="attribute"
                    caption="Attribute Name"
                    allowGrouping
                    width={200}
                />
                <Column
                    dataField="priorValue"
                    caption="Prior Value"
                    allowGrouping={false}
                    width={250}
                    dataType="string"
                    cellRender={({ data }) => renderCell(data, 'priorValue')}
                />
                <Column
                    dataField="newValue"
                    caption="New Value"
                    allowGrouping={false}
                    width={250}
                    dataType="string"
                    cellRender={({ data }) => renderCell(data, 'newValue')}
                />
                <Column
                    dataField="updatedBy"
                    caption="Updated By"
                    allowGrouping
                />

                <GroupPanel visible={true} />
                <Grouping contextMenuEnabled autoExpandAll={false} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <Scrolling mode="virtual" />
                <Paging enabled={false} />
                <FilterPanel visible={true} />
            </DataGrid>
        </Modal>
    );
};

const formatHistoryDate = (date: string | undefined): string | null =>
    date ? format(new Date(date), 'yyyy-MM-dd') : null;

const getUpdatedByName = (
    author: { firstname?: string; lastname?: string } | undefined | null,
): string => {
    if (!author?.firstname || !author?.lastname) return DASH_DASH;
    return `${author.firstname} ${author.lastname}`;
};

export const createAttributeHistory = (
    attribute: AttributeDefinition,
    entityName: string,
): AttributeHistoryData[] => {
    const ownershipAttributes = ['client', 'jvpartner'];

    if (!attribute.attributeValues?.length) return [];

    const historyData: AttributeHistoryData[] = [];

    if (ownershipAttributes.includes(attribute.attribute_code)) {
        return [];
    }
    if (attribute.is_multiselect) {
        const priorValues = attribute.attributeValues
            .map((val) => val.prior_value ?? '')
            .filter((val) => val !== '')
            .join(', ');

        const values = attribute.attributeValues
            .map((val) => val.value ?? '')
            .filter((val) => val !== '')
            .join(', ');

        const [firstValue] = attribute.attributeValues;
        historyData.push({
            updatedAt: formatHistoryDate(firstValue?.timestamps?.updated_at),
            entity: entityName,
            attribute: attribute.name,
            priorValue: priorValues,
            newValue: values,
            updatedBy: getUpdatedByName(firstValue?.last_author),
            type: attribute.type,
            format: attribute.format,
            precision: attribute.precision,
        });
    } else {
        attribute.attributeValues.forEach((attributeValue) => {
            historyData.push({
                updatedAt: formatHistoryDate(
                    attributeValue?.updated_at ??
                        attributeValue?.timestamps?.updated_at,
                ),
                entity: entityName,
                attribute: attribute.name,
                priorValue: attributeValue.prior_value,
                newValue: attributeValue.value,
                updatedBy: getUpdatedByName(attributeValue.last_author),
                type: attribute.type,
                format: attribute.format,
                precision: attribute.precision,
            });
        });
    }

    return historyData;
};

interface AttributesDataGridProps {
    isEditMode: boolean;
    setIsEditMode: (value: boolean) => void;
    selectedConfiguration: SavedConfiguration | null;
    configKey: string | null;
    setGridConfig: React.Dispatch<
        React.SetStateAction<{
            [x: string]: any;
        } | null>
    >;
    attributes: AttributeDefinition[];
    entities: Partial<Entity>[];
    entityCodes: string[];
    mutateAttributes: KeyedMutator<GetAttributesV2Response>;
    triggerSaveAction: boolean;
    setTriggerSaveAction: React.Dispatch<React.SetStateAction<boolean>>;
    triggerCancelAction: boolean;
    setTriggerCancelAction: React.Dispatch<React.SetStateAction<boolean>>;
    isHistoryModalOpen: boolean;
    setIsHistoryModalOpen: (value: boolean) => void;
}

type GroupedAttributeColumns = Record<string, AttributeFromAPI[]>;

export const AttributesDataGrid = ({
    isEditMode,
    setIsEditMode,
    selectedConfiguration,
    configKey,
    setGridConfig,
    attributes,
    entities,
    entityCodes,
    mutateAttributes,
    triggerSaveAction,
    setTriggerSaveAction,
    triggerCancelAction,
    setTriggerCancelAction,
    isHistoryModalOpen,
    setIsHistoryModalOpen,
}: AttributesDataGridProps) => {
    const dataGridRef = useRef<DataGridRef<AttributeDefinition, string>>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [expandButtonEnable, setExpandButtonEnable] =
        useState<boolean>(false);
    const [lastSavedAttributes, setLastSavedAttributes] = useState<
        AttributeData[]
    >([]);
    const [currentDataSource, setCurrentDataSource] = useState<
        DataSource | undefined
    >();
    const [attributesByCode, setAttributesByCode] = useState<
        Map<string, AttributeDefinition>
    >(new Map());

    // using ref state management to avoid triggering re-render
    const validationResultsRef = useRef<Map<string, boolean>>(new Map());
    const pendingUpdates = useRef(
        new Map<string, UpdateEntityAttributeValuesParams>(),
    );

    const WINDOW_HEIGHT = window.innerHeight;

    const onExporting = useCallback(
        async (e: ExportingEvent) => {
            const customizeCell: CustomizeCell = (options) => {
                if (!options.gridCell?.column || !options.excelCell) return;
                if (options.gridCell.rowType !== 'data') return;

                const value = options.gridCell.value;
                if (value === null || value === undefined) return;

                const column = options.gridCell.column;
                const dataField = column.dataField;

                // Skip if no dataField
                if (!dataField) return;

                // Try to get the attribute definition from the attribute definitions map
                const attribute = attributesByCode.get(dataField.toLowerCase());

                // Handle numeric values
                if (typeof value === 'number' && attribute) {
                    options.excelCell.value = value;

                    const format = attribute.format;
                    const precision = attribute.precision ?? 0;
                    const precisionStr =
                        precision > 0 ? '.' + '0'.repeat(precision) : '';

                    switch (format) {
                        case 'percent':
                            options.excelCell.numFmt = `0${precisionStr}%`;
                            break;
                        case 'currency':
                            options.excelCell.numFmt = `[$$-409]#,##0${precisionStr}`;
                            break;
                        case 'decimal':
                        default:
                            options.excelCell.numFmt = `#,##0${precisionStr}`;
                            break;
                    }
                } else if (value instanceof Date) {
                    options.excelCell.value = value;
                    options.excelCell.numFmt = 'mm/dd/yyyy';
                }
            };

            await exportExcelFromDevExtremeDataGrid(
                e,
                {
                    worksheetName: 'Attributes',
                    filename: 'Attributes.xlsx',
                },
                customizeCell,
            );
        },
        [attributesByCode],
    );

    const toggleExpanded = useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const saveState = useCallback(
        (state: SavedConfigurationState) => {
            const config = createSavedConfigurationPayload(state);
            config && setGridConfig(config);
        },
        [setGridConfig],
    );

    /**
     * AttributesByCode map helps to quickly find an attribute.
     * Any mutation to attributes should be reflected for this map as well to trigger column re-render.
     */
    useEffect(() => {
        setAttributesByCode((prevMap) => {
            const updatedAttributeByCodeMap: Map<string, AttributeDefinition> =
                new Map(prevMap);

            attributes.forEach((attribute) => {
                const existingAttr = updatedAttributeByCodeMap.get(
                    attribute.attribute_code,
                );

                const allowedValuesHasChanged =
                    existingAttr?.allowed_values?.sort().join(',') !==
                    attribute.allowed_values?.sort().join(',');
                const isMultiselectHasChanged =
                    existingAttr?.is_multiselect !== attribute.is_multiselect;

                const attrValueIsUpdated =
                    !existingAttr?.attributeValues?.every(
                        (attributeValue: AttributeValue, index) =>
                            attribute.attributeValues &&
                            attributeValue.value ===
                                attribute.attributeValues[index]?.value,
                    );

                const attrHasNewValue =
                    existingAttr?.attributeValues?.length !==
                    attribute.attributeValues?.length;

                const attrHasNewFormatOrPrecision =
                    existingAttr?.format !== attribute.format ||
                    existingAttr.precision !== attribute.precision;

                const updateMap =
                    !existingAttr ||
                    attrHasNewValue ||
                    attrValueIsUpdated ||
                    attrHasNewFormatOrPrecision ||
                    allowedValuesHasChanged ||
                    isMultiselectHasChanged;

                if (updateMap) {
                    updatedAttributeByCodeMap.set(
                        attribute.attribute_code,
                        attribute,
                    );
                }
            });

            return updatedAttributeByCodeMap;
        });
    }, [attributes]);

    /**
     * This feeds the DataGrid DataSource but is not the actual DataSource.
     * All columns and rows of data are set here as attributes and entities change.
     */
    const getCurrentColumnsAndData = useMemo((): {
        columns: GroupedAttributeColumns;
        data: AttributeData[];
        historyData: AttributeHistoryData[];
        summaryItems: JSX.Element[];
    } => {
        const historyData: AttributeHistoryData[] = [];

        const groupedColumns: GroupedAttributeColumns =
            attributes.reduce<GroupedAttributeColumns>((acc, attribute) => {
                if (!acc[attribute.category]) {
                    acc[attribute.category] = [];
                }

                acc[attribute.category].push({
                    title: attribute.name,
                    key: attribute.attribute_code.toLowerCase(),
                    dataIndex: attribute.attribute_code.toLowerCase(),
                    category: attribute.category,
                });

                return acc;
            }, {});

        const columns = {
            ...groupedColumns,
            '': [
                {
                    title: 'Property Code',
                    key: 'entity_display_code',
                    dataIndex: 'entity_display_code',
                    category: '',
                },
                {
                    title: 'Entity Code', // not displayed, useful to track an entity
                    key: 'entity_code',
                    dataIndex: 'entity_code',
                    category: '',
                },
                {
                    title: 'Property',
                    key: 'name',
                    dataIndex: 'name',
                    category: '',
                },
            ],
        };

        const entitiesByEntityCode: Map<string, Partial<Entity>> = new Map();
        for (const entity of entities) {
            entitiesByEntityCode.set(entity.entityCode as string, entity);
        }

        const data: AttributeData[] = entityCodes.map((entityCode) => {
            const entity = entitiesByEntityCode.get(entityCode);

            const row: AttributeData = {
                key: entity?.displayCode ?? '', // for DevExtreme KeyExpr purposes
                entity_display_code: entity?.displayCode ?? '',
                entity_code: entity?.entityCode ?? '',
                name: entity?.displayName ?? '',
            };

            attributes.forEach((attribute: AttributeDefinition) => {
                const dataIndex = attribute.attribute_code.toLowerCase();

                if (!attribute.is_multiselect) {
                    const attributeValue: AttributeValue | undefined =
                        attribute.attributeValues?.find(
                            (value) => value.entity_code === entityCode,
                        );

                    if (attribute.type === AttributeTypes.boolean.value) {
                        row[dataIndex] = attributeValue?.value === 'true';
                    }

                    if (attribute.type !== AttributeTypes.boolean.value) {
                        row[dataIndex] = attributeValue?.value ?? '';
                    }
                }

                if (attribute.is_multiselect) {
                    const attributeValues = attribute.attributeValues?.filter(
                        (value) => value.entity_code === entityCode,
                    );
                    row[dataIndex] =
                        attributeValues
                            ?.map((value) => value.value)
                            .filter(Boolean) ?? [];
                }

                const attributeWithFilteredValues = {
                    ...attribute,
                    attributeValues: attribute.attributeValues?.filter(
                        (value) => value.entity_code === entityCode,
                    ),
                };
                const attributeHistory = createAttributeHistory(
                    attributeWithFilteredValues,
                    entity?.displayName ?? '',
                );

                historyData.push(...attributeHistory);
            });

            return row;
        });

        const summaryItems = attributes.reduce<JSX.Element[]>(
            (result, attributeDefinition) => {
                if (!attributeDefinition.summary_type) {
                    return result;
                }

                const format = mapAttributeFormat(
                    attributeDefinition?.type,
                    attributeDefinition?.format,
                    attributeDefinition?.summary_type === SummaryTypes.average
                        ? Math.max(2, attributeDefinition?.precision ?? 0)
                        : attributeDefinition?.precision ?? 0,
                );

                const commonSummaryAttributes = {
                    column: attributeDefinition.attribute_code.toLowerCase(),
                    showInColumn:
                        attributeDefinition.attribute_code.toLowerCase(),
                    summaryType: attributeDefinition.summary_type ?? undefined,
                    valueFormat: format,
                    displayFormat: getSummaryLabel(
                        attributeDefinition.summary_type,
                    ),
                    name: attributeDefinition.name,
                };

                const summaryItem = <TotalItem {...commonSummaryAttributes} />;

                const groupItem = (
                    <GroupItem
                        {...commonSummaryAttributes}
                        alignByColumn={true}
                    />
                );

                return [...result, summaryItem, groupItem];
            },
            [],
        );

        return { columns, summaryItems, data, historyData };
    }, [attributes, entities, entityCodes]);

    useEffect(() => {
        const { data } = getCurrentColumnsAndData;
        setCurrentDataSource(new DataSource(cloneDeep(data)));
    }, [getCurrentColumnsAndData]);

    const setCellAndPendingUpdateValue = (
        newRowData: AttributeData,
        value: AttributeGridRowValueTypes,
        currentRowData: AttributeData,
        attribute?: AttributeDefinition,
    ) => {
        if (!attribute) {
            return;
        }

        if (
            reservedDataGridAttributesColumns.includes(attribute.attribute_code)
        ) {
            return;
        }

        // this updates the data in the grid
        newRowData[attribute.attribute_code] =
            formatValue(
                value,
                false,
                attribute.type,
                attribute.format,
                attribute.precision,
                attribute.is_multiselect,
            ) ?? false;

        // once value is formatted, prepare payload for the endpoint
        const preparedPayload: UpdateEntityAttributeValuesParams =
            prepareAttributeValuesPayload(
                attribute,
                newRowData[attribute.attribute_code],
                currentRowData.entity_code as string,
            );

        // update the pending updates to be send when saving
        pendingUpdates.current.set(
            `${currentRowData.entity_code}_${attribute.attribute_code}`,
            preparedPayload,
        );
    };

    const gridColumns: JSX.Element[] = useMemo(() => {
        if (!currentDataSource) {
            return [];
        }

        const { columns } = getCurrentColumnsAndData;

        const groupedColumns: [string, AttributeFromAPI[]][] =
            Object.entries(columns);

        return (
            groupedColumns
                .sort(([groupA], [groupB]) => {
                    if (groupA === 'Property') return -1;
                    if (groupB === 'Property') return 1;

                    const configColumns =
                        selectedConfiguration?.filters_json?.grid_config
                            ?.columns ?? {};

                    const groupAVisibleIndex =
                        configColumns[groupA]?.visibleIndex;
                    const groupBVisibleIndex =
                        configColumns[groupB]?.visibleIndex;

                    if (
                        groupAVisibleIndex === undefined ||
                        groupBVisibleIndex === undefined
                    ) {
                        return groupA.localeCompare(groupB);
                    }

                    return groupAVisibleIndex > groupBVisibleIndex ? 1 : -1;
                })
                .map(([group, columns]: [string, AttributeFromAPI[]]) => {
                    return (
                        <Column
                            caption={group}
                            key={group}
                            fixed={group === ''}
                            alignment="center"
                            name={group}
                        >
                            {columns
                                ?.filter(
                                    (column: AttributeFromAPI) =>
                                        column.key !== 'entity_code',
                                )
                                .map((column) => {
                                    return getAttributesColumn({
                                        key: column.key,
                                        column,
                                        attributesByCode,
                                        selectedConfiguration,
                                        setCellAndPendingUpdateValue,
                                        isEditMode,
                                    });
                                })}
                        </Column>
                    );
                }) ?? []
        );
    }, [
        getCurrentColumnsAndData,
        selectedConfiguration,
        attributesByCode,
        currentDataSource,
        isEditMode,
    ]);

    const saveAttributes = useCallback(async () => {
        try {
            const newAttributeValues =
                await createOrUpdateEntityAttributeValues(
                    Array.from(pendingUpdates.current.values()),
                );

            await mutateAttributes(
                (currentData: GetAttributesV2Response | undefined) => {
                    if (!currentData) {
                        return undefined;
                    }

                    const updatedAttributeDefinitions =
                        currentData.attributeDefinitions?.map(
                            (attributeDefinition) => {
                                // Find updated values for the current AttributeDefinition
                                const attributeUpdatedValues =
                                    newAttributeValues.filter(
                                        (newValue) =>
                                            newValue.attribute_definition_id ===
                                            attributeDefinition.id,
                                    );

                                const updatedAttributeValueIds = new Set(
                                    attributeUpdatedValues.map(
                                        (updatedValue) => updatedValue.id,
                                    ),
                                );

                                const deletedAttributeValueIds = Array.from(
                                    pendingUpdates.current.values(),
                                ).flatMap(
                                    (update) =>
                                        update.deleted_attribute_value_ids ||
                                        [],
                                );

                                // filter in non-deleted attribute values, and non-updated attribute values
                                const remainingAttributeValues =
                                    attributeDefinition.attributeValues?.filter(
                                        (existingValue) => {
                                            const isNotDeleted =
                                                !deletedAttributeValueIds.includes(
                                                    existingValue.id,
                                                );
                                            const isNotUpdated =
                                                !updatedAttributeValueIds.has(
                                                    existingValue.id,
                                                );
                                            return isNotDeleted && isNotUpdated;
                                        },
                                    );

                                const attributeUpdatedEntityCode =
                                    attributeUpdatedValues[0]?.entity_code;

                                const isMultiSelectUpdated =
                                    attributeDefinition.is_multiselect &&
                                    attributeUpdatedValues.some(
                                        (v) => v.value !== null,
                                    );

                                // Filter remaining attributes by entityCode to avoid duplication in the grid
                                const filteredRemainingAttributeValues =
                                    isMultiSelectUpdated
                                        ? remainingAttributeValues?.filter(
                                              (value) =>
                                                  value.entity_code !==
                                                  attributeUpdatedEntityCode,
                                          )
                                        : remainingAttributeValues;

                                return {
                                    ...attributeDefinition,
                                    attributeValues: [
                                        ...(filteredRemainingAttributeValues ||
                                            []),
                                        ...(attributeUpdatedValues || []),
                                    ],
                                };
                            },
                        );

                    return {
                        ...currentData,
                        attributeDefinitions: updatedAttributeDefinitions,
                    };
                },
                false, // use cache and avoid server revalidation
            );

            setIsEditMode(false);
            message.success(`Attributes updated successfully`);
        } catch (e) {
            message.error(`Failed to update attributes`);
        } finally {
            pendingUpdates.current.clear();
        }
    }, [mutateAttributes, setIsEditMode]);

    const handleOnEditSaveClick = useCallback(async () => {
        if (!isEditMode) {
            const { data } = getCurrentColumnsAndData;
            setLastSavedAttributes(cloneDeep(data));
            setIsEditMode(true);
            return;
        }

        const dataGridInstance = dataGridRef.current?.instance();

        if (dataGridInstance) {
            await dataGridInstance.saveEditData();
        }

        const allRowsValid = Array.from(
            validationResultsRef.current.values(),
        ).every((isValid) => isValid);

        if (!allRowsValid) {
            message.error(
                'Some rows have validation errors. Please fix them before saving.',
            );
            return;
        }

        saveAttributes();
    }, [getCurrentColumnsAndData, isEditMode, saveAttributes, setIsEditMode]);

    // Triggered for each row separately when dataGridInstance.saveEditData() is executed
    const handleOnRowValidating = (event: {
        key: string;
        isValid: boolean;
    }) => {
        const rowKey = event.key;
        validationResultsRef.current.set(rowKey, event.isValid);
    };

    const handleCancel = useCallback(() => {
        setIsEditMode(false);
        setCurrentDataSource(new DataSource(cloneDeep(lastSavedAttributes)));
        pendingUpdates.current.clear();
    }, [setIsEditMode, lastSavedAttributes]);

    useEffect(() => {
        if (triggerSaveAction) {
            handleOnEditSaveClick();
        }
        setTriggerSaveAction(false);
    }, [triggerSaveAction, handleOnEditSaveClick, setTriggerSaveAction]);

    useEffect(() => {
        if (triggerCancelAction) {
            handleCancel();
        }
        setTriggerCancelAction(false);
    }, [triggerCancelAction, handleCancel, setTriggerCancelAction]);

    return useMemo(
        () => (
            <>
                <AttributeValueHistoryModal
                    isHistoryModalOpen={isHistoryModalOpen}
                    setIsHistoryModalOpen={setIsHistoryModalOpen}
                    historyData={getCurrentColumnsAndData.historyData}
                />
                <DataGrid
                    ref={dataGridRef}
                    key={`${selectedConfiguration?.id ?? ''}_${configKey ?? ''}`}
                    keyExpr={'key'}
                    dataSource={currentDataSource}
                    height={WINDOW_HEIGHT - DATA_GRID_HEIGHT_OFFSET}
                    elementAttr={{
                        id: 'gridContainer',
                    }}
                    onRowValidating={handleOnRowValidating}
                    className={dataGridStyles}
                    onExporting={onExporting}
                    allowColumnResizing={true}
                    columnResizingMode="widget"
                    allowColumnReordering={true}
                    showRowLines={true}
                    showBorders={true}
                    scrolling={{ mode: 'standard' }}
                    showColumnLines={true}
                    hoverStateEnabled={true}
                    onContentReady={(e) =>
                        onContentReady({
                            e,
                            toggleFunc: setExpandButtonEnable,
                        })
                    }
                    onInitialized={(e: InitializedEvent) => {
                        if (selectedConfiguration && e?.component) {
                            e.component.clearSorting();
                            applyStoredConfiguration(
                                e.component,
                                selectedConfiguration,
                            );
                        }
                    }}
                >
                    <Editing
                        mode="batch"
                        allowUpdating={isEditMode}
                        selectTextOnEditStart={true}
                        startEditAction="click"
                    />
                    <Toolbar>
                        <Item location="after" name="columnChooserButton" />
                        <Item name="exportButton" />
                        <Item name="searchPanel" />
                        <Item name="groupPanel" />
                        <Item location="before">
                            <ExpandAndCollapseButton
                                expanded={expanded}
                                toggleExpanded={toggleExpanded}
                                expandButtonEnable={expandButtonEnable}
                            />
                        </Item>
                    </Toolbar>
                    <HeaderFilter
                        allowSelectAll={true}
                        visible={true}
                        allowSearch={true}
                        height={500}
                    />
                    <FilterRow visible={true} />
                    <FilterPanel visible={true} />

                    <Grouping
                        autoExpandAll={expanded}
                        contextMenuEnabled={true}
                    />
                    <GroupPanel visible={'auto'} />

                    {currentDataSource ? gridColumns : null}

                    <Summary>
                        <TotalItem
                            column="Property"
                            summaryType="count"
                            valueFormat={{
                                type: 'fixedPoint',
                                precision: 0,
                            }}
                            displayFormat="{0} Properties"
                        />
                        <GroupItem
                            column="Property"
                            summaryType="count"
                            alignByColumn={false}
                            showInGroupFooter={false}
                            displayFormat="{0} Properties"
                        />

                        {getCurrentColumnsAndData.summaryItems}
                    </Summary>
                    <Paging enabled={true} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[20, 40, 60, 80, 100]}
                        showNavigationButtons={true}
                        showInfo={true}
                        infoText="Page {0} of {1} ({2} items)"
                    />

                    <SearchPanel
                        visible={true}
                        highlightCaseSensitive={false}
                    />

                    <Export enabled={true} allowExportSelectedData={false} />
                    <ColumnChooser
                        enabled={true}
                        mode={'select'}
                        height={500}
                        sortOrder={'asc'}
                        allowSearch={true}
                    >
                        <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                        />
                        <ColumnChooserSelection
                            allowSelectAll={true}
                            recursive={true}
                        />
                    </ColumnChooser>
                    <StateStoring
                        enabled={true}
                        savingTimeout={100}
                        type="custom"
                        customSave={saveState}
                    />
                </DataGrid>
            </>
        ),
        [
            WINDOW_HEIGHT,
            configKey,
            currentDataSource,
            expandButtonEnable,
            gridColumns,
            isEditMode,
            expanded,
            selectedConfiguration,
            dataGridRef,
            onExporting,
            saveState,
            toggleExpanded,
            isHistoryModalOpen,
        ],
    );
};
