import { Timestamps } from './timestamp-types';
import { User } from './user-types';
import { SummaryTypes } from './util-types';

export enum AttributeTypeCodes {
    STRING = 'string',
    NUMBER = 'number',
    DATETIME = 'datetime',
    BOOLEAN = 'boolean',
}

export const allowedAttributeSummaryTypes: SummaryTypes[] = [
    SummaryTypes.sum,
    SummaryTypes.average,
    SummaryTypes.max,
    SummaryTypes.min,
];

export interface AttributeDefinition {
    id: string;
    type: AttributeTypeCodes;
    name: string;
    format: string | null;
    precision: number | null;
    attribute_code: string;
    summary_type: SummaryTypes | null;
    category: string;
    is_groupable: boolean;
    is_filterable: boolean;
    is_multiselect: boolean;
    is_hidden: boolean;
    allowed_values: string[] | null;
    client_id: string | null;
    timestamps: Timestamps;
    attributeValues: AttributeValue[] | undefined;
}

export interface AttributeValue {
    id: string;
    attribute_definition_id: string;
    entity_code: string;
    value: string;
    prior_value: string | null;
    created_by: number;
    updated_at: string | null;
    updated_by: number | null;
    deleted_at: string | null;
    timestamps: Timestamps;
    attributeDefinition: AttributeDefinition;
    last_author: User;
}

export interface AttributeHistoryData {
    updatedAt: string | null;
    entity: string;
    attribute: string;
    priorValue: string | null;
    newValue: string | null;
    updatedBy: string;
    type: AttributeTypeCodes;
    format: string | null;
    precision: number | null;
}

export interface AttributeValueHistory {
    value: string | null;
    prior_value: string | null;
}
